var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"myFeedBack-page"},[_c('div',{staticClass:"myFeedBack-header"},[_c('van-tabs',{attrs:{"color":"#1677FF","title-active-color":"#1677FF"},on:{"click":_vm.onClick},model:{value:(_vm.backStatus),callback:function ($$v) {_vm.backStatus=$$v},expression:"backStatus"}},[_c('van-tab',{attrs:{"title":"全部","name":""}}),_vm._l((_vm.dictoryBycode_STATUS_CODE),function(item,index){return _c('van-tab',{key:index,attrs:{"title":item.name,"name":item.code}})})],2)],1),_c('div',{class:_vm.selectedFlag ? 'myFeedBack-select' : 'myFeedBack-selected'},[_c('van-dropdown-menu',{attrs:{"active-color":"#1677FF","close-on-click-overlay":false}},[_c('van-dropdown-item',{ref:"item",staticStyle:{"color":"red"},attrs:{"title":_vm.titleValue}},[_c('drop-down-selection',{attrs:{"currentValue":_vm.currentIndex,"dataValArr":_vm.feedbackTerms},on:{"getSelectValue":_vm.getSelectValueInfo}})],1)],1)],1),(_vm.dataInfoFlag)?_c('van-pull-refresh',{attrs:{"disabled":true},on:{"refresh":_vm.onRefresh},model:{value:(_vm.refreshing),callback:function ($$v) {_vm.refreshing=$$v},expression:"refreshing"}},[_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":"没有更多了"},on:{"load":_vm.selectFeedbackHandling},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('div',{staticClass:"myFeedBack-content"},[_c('ul',_vm._l((_vm.dataInfo),function(item,index){return _c('li',{key:index},[_c('div',{staticClass:"title-li"},[_c('div',{staticClass:"left-txt"},[_c('i',[_c('img',{attrs:{"src":item.status === 'nh_00'
                        ? _vm.handle_First
                        : item.status === 'nh_01'
                        ? _vm.handle_Second
                        : item.status === 'nh_02'
                        ? _vm.handle_Third
                        : item.status === 'nh_03'
                        ? _vm.handle_Fourth
                        : '',"alt":""}})]),_c('span',[_vm._v(_vm._s(item.status === 'nh_00' ? '办理中' : item.status === 'nh_01' ? '已办结' : item.status === 'nh_02' ? '已拒绝' : item.status === 'nh_03' ? '已取消' : ''))])]),_c('em',[_vm._v(_vm._s(item.createdTime))])]),_c('div',{staticClass:"content-li"},[_c('p',{staticClass:"title"},[_vm._v(" "+_vm._s(item.feedbackCode === '0101' ? '违章举报' : item.feedbackCode === '0102' ? '意见反馈' : item.feedbackCode === '0103' ? '报事报修' : '')+" ")]),_c('p',{staticClass:"van-ellipsis"},[_vm._v("内容: "+_vm._s(item.content))]),_c('p',{staticClass:"van-ellipsis"},[_vm._v("地址: "+_vm._s(_vm.isaddress(item.address)))])]),_c('div',{staticClass:"detail-li",on:{"click":function($event){return _vm.gotoDetailPage(item)}}},[_c('span',[_vm._v("查看详情")]),_c('van-icon',{attrs:{"name":"arrow"}})],1)])}),0)])])],1):_c('no-data')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }